import { priceInCentsToStr } from '@/utils/priceCalc';
import PartnersSupportClient from '@/utils/partnersSupportClient';
import { DELIEVERY_TYPE_PICK_UP } from '@/consts/productConsts';
import { getStateAbbreviation, isUserStateSupportedBySeller } from '@/src/utils/statesHelper';
import { SHIPPED_VIA_SBG } from '@/consts/sellerConsts';
import Dayjs from 'dayjs';
import { isDevEnv } from '@/src/utils/environment';
import { GOODFRIENDS } from '@/consts/siteConsts';
import { Typography, Link } from '@mui/material';
export const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

const validateState = ({order, seller}) => {

  
  let state = order?.state;
  if (!state) {
    return 'Please fill your state';
  }
  
  state = getStateAbbreviation(state)
  if (!state) {
    return 'We do not recognize your state. Please try to enter the state code.'
  }
  
  // if (seller.key === GOODFRIENDS && state == 'AL') {
  //   return (<Typography sx={{ fontWeight: 'bold' }} align='center'>
  //   Shipping to California? 
  //     <Link href="https://vinoshipper.com/shop/cal_bev-go_llc"> click here </Link>
  //   </Typography>)
  // }
  
  if (!isUserStateSupportedBySeller({ seller, userState: state })) {
    return `We regretfully do not ship to your state. Contact us if you have any questions.`
  }
};


export function validateMinimumShipping({
  order,
  priceInCents = 0,
  bottles = 0,
  minimumShippingInfo,
  zip,
  productsByKey
}) {
  if (!minimumShippingInfo) {
    return
  }
  if (
    minimumShippingInfo.type === 'bottles' &&
    bottles < minimumShippingInfo.bottles
  ) {
    return `${minimumShippingInfo.bottles} bottles minimum is required per order`
  }
  if (
    minimumShippingInfo.type === 'priceInCents' &&
    priceInCents < minimumShippingInfo.priceInCents
  ) {
    const priceStr = priceInCentsToStr(minimumShippingInfo.priceInCents, {
      useSymbol: true,
    })
    return `${priceStr} minimum is required per order`
  }
  return
}


function validateDateOfBirth(dateOfBirth) {
  if (isDevEnv()) {
    return;
  }
  if (!dateOfBirth) {
    return 'Please fill your date of birth';
  }
  const dateOfBirthDayjs =
    typeof dateOfBirth === 'string' ? Dayjs(dateOfBirth) : dateOfBirth
  const olderThan21 = dateOfBirthDayjs.add(21,'year').isBefore(Date.now());
  console.log({olderThan21})
  if (!olderThan21) {
    return 'You must be at least 21 years old'
  }
  return false;
}

// eslint-disable-next-line no-unused-vars
export function validateOrder({order, seller}) {
  
  let bottles = 0;
  Object.values(order.transactionPriceInfo?.productsToPurchase || {}).forEach(p=> bottles = bottles + p.amount);
  const priceInCents = order.transactionPriceInfo?.subtotalInCents;
  const errors = {
    firstName:  !order.firstName && 'Please fill your first name',
    lastName:  !order.lastName && 'Please fill your last name',
    email: (!order.email || !validateEmail(order.email)) && 'Please enter a valid email',
    address1: !order.address1 && 'Please fill your address', 
    city: !order.city && 'Please fill your address', 
    zip: !order.zip && 'Please fill your address', 
    state: !order.state && 'Please fill your address', 
  };

  const needToValidateDateOfBirth = true;
  if (needToValidateDateOfBirth) {
    errors.dateOfBirth = validateDateOfBirth(order.dateOfBirth)
  }
  
  if (order.delieveryType !== DELIEVERY_TYPE_PICK_UP) {
    errors.state = validateState({order,seller});
    errors.minShipment = validateMinimumShipping({
      order,
      priceInCents,
      bottles,
      minimumShippingInfo: seller?.minimum_shipping_info_in_cents,
      zip: order.zip,
    })
  }
  errors.terms = !order.terms && 'Please agree to Terms of Service';
  const anyErrors = Object.values(errors).find((v) => (v));
  return anyErrors && {
    msg: anyErrors,
    errors,
  };
}