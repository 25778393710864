
import React from 'react';
import MarkdownView from '@/src/components/Markdown/MarkdownView';
import Box from '@mui/material/Box';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const FaqQuestionsAnswers = ({questionsAndAnswers}) => {
  
  if (!questionsAndAnswers) {
    return null;
  }
  return  (
    <Box>
      <Box>
        {questionsAndAnswers.map((item, i) => (
          <Box
            component={Accordion}
            key={i}
            padding={1}
            marginBottom={i === item.length - 1 ? 0 : 2}
            borderRadius={2}
            sx={{
              '&::before': {
                display: 'none',
              },
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id={`panel1a-header--${i}`}
            >
              <Typography fontWeight={600}>{item.title}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {Array.isArray(item.subtitle) ? (
                item.subtitle.map((text, i) => (
                  <Typography key={i} color="text.secondary">
                    {text}
                  </Typography>
                ))
              ) : (
                <Typography color="text.secondary">{item.subtitle}</Typography>
              )}
              <MarkdownView>{item.body}</MarkdownView>
            </AccordionDetails>
          </Box>
        ))}
      </Box>
    </Box>
  )
};

export default FaqQuestionsAnswers;
