import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import { ProductCardSipjoy } from '../ProductCard'
import Link from 'next/link'
import { Button, Grid } from '@mui/material';

const PRODUCT_CARD_REF = 'highlighted_products';

const Recommendations = ({ recommendationData }) => {
  if (!recommendationData.products) {
    return null;
  }
  const theme = useTheme();

  const products = recommendationData.products;
  const productsComp = [];
  const prepareProductsForSlider = () => {
    products.forEach((product, i) => {
      productsComp.push(
        <Box key={i} sx={{ padding: '0 10px' }}>
          <ProductCardSipjoy
            producerKey={product.producer?.key}
            showSourceInsteadDetails={true}
            product={product}
            locRef={PRODUCT_CARD_REF}
          />
        </Box>,
      );
    });
  };
  prepareProductsForSlider();
  // it seems that the slider has problems to render small amount of products cards
  if (products.length < 5) {
    prepareProductsForSlider();
  }

  const title = recommendationData.title || 'Popular Wines';
  const titleComp = (title) => (
    <Typography
      variant="h4"
      sx={{
        textTransform: 'uppercase',
        fontWeight: '500',
        marginBottom: '20px',
      }}
      color={theme.palette.primary.main}
    >
      {title}
    </Typography>
  );
  return (
    <>
      <Box marginBottom={2}>
        {title && titleComp(title)}
        <Box marginTop={1}>
          {recommendationData.body ? (
            <>
              <Typography
                variant={'h6'}
                component={'p'}
                color={theme.palette.primary.contrastText}
                data-aos="fade-up"
              >
                {recommendationData.header}
              </Typography>
              <Typography
                variant={'body1'}
                component={'p'}
                color={theme.palette.primary.contrastText}
                data-aos="fade-up"
                sx={{ whiteSpace: 'pre-line' }}
              >
                {recommendationData.body}
              </Typography>
            </>
          ) : (
            <Typography
              variant={'h4'}
              component={'p'}
              data-aos="fade-up"
              align={'center'}
              color={theme.palette.primary.contrastText}
            >
              {recommendationData.header}
            </Typography>
          )}
          {recommendationData.person?.primary && (
            <ListItem component="div" disableGutters data-aos={'fade-up'}>
              <ListItemAvatar>
                <Box
                  component={Avatar}
                  width={48}
                  height={48}
                  src={'https://assets.maccarianagency.com/avatars/img6.jpg'}
                  marginRight={2}
                />
              </ListItemAvatar>
              <ListItemText
                primary={recommendationData.person.primary}
                secondary={recommendationData.person.primary}
              />
            </ListItem>
          )}
        </Box>
      </Box>
      <Grid container spacing={2} flexWrap="nowrap">
        {productsComp}
      </Grid>

      <Box
        marginTop={8}
        maxWidth={720}
        sx={{ marginLeft: 'auto', marginRight: 'auto' }}
      >
        <Link href="/wines?ref=popular_wines">
          <Button fullWidth variant="contained" size="large">
            More wine
          </Button>
        </Link>
      </Box>
    </>
  )
};

Recommendations.propTypes = {
  recommendationData: PropTypes.object.isRequired,
};

export default Recommendations;
