/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { nextDay } from 'date-fns';
import { LegendToggleRounded } from '@mui/icons-material';

const TextFieldsArray = ({
  header,
  values = [],
  onChange,
  addNewText = 'Add New',
  possibleValues,
  headerVariant = 'caption',
  divideBetweenComponents = false,
  children,
}) => {
  const theme = useTheme();
  const addNewRow = () => {
    onChange && onChange([...values, '']);
  };
  const removeRow = (loc) => {
    const newValues = [...values];
    newValues.splice(loc, 1);
    onChange && onChange(newValues);
  };
  const onTextFieldChange = (loc, value) => {
    const newValues = [...values];
    newValues[loc] = value;
    onChange && onChange(newValues);
  };

  const createEditComp = (value, index) => {
    if (children) {
      const childrenWithProps = React.Children.map(children, child => {
        // Checking isValidElement is the safe way and avoids a typescript
        // error too.
        if (React.isValidElement(child)) {
          const fieldName = child.props.field;
          if (!fieldName) {
            console.error('You must supply field prop');
            return null;
          }
          const onChange = (val) => {
            const newValue = {
              ...value,
              [fieldName]: child.props.handleExtractValue
                ? child.props.handleExtractValue(val)
                : val,
            };
            
            onTextFieldChange(index, newValue);
          };
          const fieldValue = value && fieldName && value[fieldName];
          return React.cloneElement(child, { onChange,  value: fieldValue || '' });
        }
        return child;
      });
      return childrenWithProps;
    }
    if (possibleValues && possibleValues.length > 0) {
      return (
        <Select
          sx={{ marginBottom: '5px', width: '350px' }}
          value={value}
          label="Age"
          onChange={(event) => {
            onTextFieldChange(index, event.target.value);
          }}
        >
          {possibleValues.map((o, i) => {
            return (<MenuItem key={i} value={o?.value}>{o?.text}</MenuItem>);
          })}
        </Select>
      );
    }
    return (
      <TextField
        sx={{ marginBottom: '5px', width: '350px' }}
        size="small"
        InputLabelProps={{ shrink: true }}
        variant="outlined"
        value={value || ''}
        onChange={(event) => {
          onTextFieldChange(index, event.target.value);
        }}
      />
    );
  };

  const textFieldsComponents = [];
  for (let index = 0; index < values.length; index++) {
    const value = values[index];
    textFieldsComponents.push(
      <Box key={index}>
        {createEditComp(value, index)}
        <IconButton aria-label="Delete" onClick={() => removeRow(index)}>
          <DeleteIcon />
        </IconButton>
        {divideBetweenComponents && (<Divider sx={{margin:'10px', borderColor: '#b3b3b31f'}}/>)}
      </Box>,
    );
  }

  return (
    <Box>
      <Typography variant={headerVariant} color="text.secondary" component="p" gutterBottom>
        {header}{' '}
      </Typography>
      {textFieldsComponents}
      <Button
        variant="outlined"
        startIcon={<AddBoxIcon />}
        onClick={addNewRow}
      >
        {addNewText}
      </Button>
    </Box>
  );
};

TextFieldsArray.propTypes = {
  header: PropTypes.string,
  addNewText: PropTypes.string,
  values: PropTypes.array.isRequired,
  onChange: PropTypes.func,
  possibleValues: PropTypes.array,
  headerVariant: PropTypes.string,
  divideBetweenComponents: PropTypes.bool,
  children: PropTypes.array
};

export default TextFieldsArray;
