import { useSite } from '@/src/contexts/SiteContext';
import React from 'react';
import MarkdownView from '@/src/components/Markdown/MarkdownView';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import FaqQuestionsAnswers from './FaqQuestionsAnswers';

export const FAQ_TYPE_GENERAL ='general';
export const FAQ_TYPE_CONFIRMATION ='confirmation';
export const FAQ_TYPE_CHECKOUT ='checkout';
export const FAQ_TYPE_CONTACT ='contact';

const Faq = ({type = FAQ_TYPE_GENERAL, faqSection}) => {
  const { site } = useSite()

  let section;
  if (faqSection) {
    section = faqSection;
  } else {
    if (!site) {
      return null;
    }
    if (type === FAQ_TYPE_CHECKOUT) {
      section = site?.checkout_faq_section || site?.faq_page
    } else if (type === FAQ_TYPE_CONFIRMATION) {
      section = site?.confirmation_faq_section || site?.faq_page
    } else if (type === FAQ_TYPE_CONTACT) {
      section = site?.contact_faq_section || site?.faq_page
    } else {
      section = site?.faq_page
    }
  }
  const questionsAndAnswers = section?.questionsAndAnswers || [];
  const title = section?.header?.title || 'F.A.Q.';
  const body = section?.header?.body;

  if (questionsAndAnswers.length === 0) {
    return null
  }
  return (
    <Box>
      <Box
        marginBottom={4}
        display={'flex'}
        flexDirection={'column'}
        alignItems={'center'}
      >
        <Typography fontWeight={700} variant={'h3'} align={'center'} gutterBottom>
          {title}
        </Typography>
        {body ? (
          <Box>
            <MarkdownView>{body}</MarkdownView>
          </Box>
        ) : null}
      </Box>
      <FaqQuestionsAnswers questionsAndAnswers={questionsAndAnswers}/>
    </Box>
  )
};

export default Faq;
